import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import QRCodeReact from 'qrcode.react'
import uid from 'uuid'

import { NavInSite } from '../../components/nav'
import Loading from '../../components/loading'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import { Page } from '../'
import Picture from '../../components/picture'
import { moduleLabel, trackLabel, apiCache } from '../../stores'

class SiteForm extends Component {
  state = {
    error: '',
    saving: false,
    loading: false,
    creating: false,
    site: {},
    experiences: [],
    prodStatus: {}
  }

  async createScenario(name, modules, experienceId, onEnd) {
    const scenario = await api.scenarios.create({ name, experienceId, onEnd })
    for (let order = 0; order < modules.length; order++) {
      await api.modules.create({ ...modules[order], scenarioId: scenario._id, experienceId, order })
    }
    return scenario
  }

  async createPoint(name, gameModules, qrcode, track, onEnd, order) {
    const { experienceId, _id: trackId } = track

    const clues = await this.createScenario(
      name + ' - indices',
      [{ type: 'gallery', title: 'Indices', canGoBack: true }],
      experienceId
    )
    const games = await this.createScenario(name + ' - jeu', gameModules, experienceId, onEnd)
    return api.points.create({
      name,
      trackId,
      location: [46 + Math.random(), 4 + Math.random()],
      active: true,
      visible: true,
      order,
      check: {
        type: 'qrcode',
        code: qrcode, // 'https://explorgames.com/qrcode/0' + index,
        clue: clues._id,
        scenario: games._id
      },
      experienceId
    })
  }

  createAdventureTrack = async xp => {
    const { _id: experienceId } = xp
    const adventureTrack = await api.tracks.create({
      experienceId,
      type: 'adventure',
      minZoom: 14,
      maxZoom: 18,
      name: 'Aventure'
    })
    xp.startTrack = adventureTrack._id
    const scenariosInButton = []
    await api.experiences.upsert(xp)
    for (let index = 1; index < 9; index++) {
      const point = await this.createPoint(
        'Point 0' + index,
        [
          { type: 'conversation', title: 'avant jeu', canGoBack: false },
          { type: 'markdown', title: 'jeu' },
          { type: 'score_conversation', title: 'après jeu' }
        ],
        'https://explorgames.com/qrcode/0' + index,
        adventureTrack,
        [],
        index
      )
      scenariosInButton.push(point.check.scenario)
    }

    let point = await this.createPoint(
      'Fin',
      [
        { type: 'conversation', title: 'avant jeu de fin', canGoBack: false },
        { type: 'markdown', title: 'jeu de fin' },
        { type: 'score_conversation', title: 'score global', scoreType: 'experience' },
        { type: 'selfie', title: 'Selfie' },
        {
          type: 'rating',
          title: 'Notation',
          ratings: [
            { key: 'fun', label: 'Avez-vous passé un bon moment ?' },
            { key: 'difficulty', label: 'Est-ce que le niveau de difficulté est adapté ?' },
            { key: 'next', label: 'Êtes-vous prêts à embarquer à nouveau ? ' }
          ]
        },
        {
          type: 'markdown',
          title: 'Crédits',
          code: `# Crédits

* ExplorGames® Découverte
* Création et scénario original: La fine équipe d’Atelier Nature 
* Contenus historiques :  
* Ecriture des textes: 
* Illustrateur : 
* Images : Freepix - pixabay
* Voix : 
* Musique : 
      `
        },
        { type: 'gallery', title: 'Parternaires' }
      ],
      'https://explorgames.com/qrcode/13',
      adventureTrack,
      [{ type: 'disable_all_points' }],
      10
    )

    scenariosInButton.push(point.check.scenario)

    const hordeSuccess = await this.createScenario(
      'Horde succes',
      [{ type: 'score_conversation', title: 'horde - réussite', scoreType: 'track' }],
      experienceId,
      [{ type: 'load_track', track: adventureTrack._id }]
    )
    scenariosInButton.push(hordeSuccess._id)

    const hordeTrack = await api.tracks.create({
      experienceId,
      type: 'horde',
      minZoom: 14,
      maxZoom: 18,
      name: 'Horde'
    })

    point = await api.points.create({
      name: 'objectif horde ',
      trackId: hordeTrack._id,
      location: [46 + Math.random(), 4 + Math.random()],
      experienceId: xp._id,
      scenario: hordeSuccess._id,
      distance: 15,
      active: true,
      visible: true
    })

    point = await this.createPoint(
      'Horde',
      [{ type: 'conversation', title: 'avant horde' }],
      'https://explorgames.com/qrcode/10',
      adventureTrack,
      [{ type: 'load_track', track: adventureTrack._id }],
      11
    )
    scenariosInButton.push(point.check.scenario)

    const chaseSuccess = await this.createScenario(
      'chase succes',
      [{ type: 'score_conversation', title: 'chase - réussite', scoreType: 'track' }],
      experienceId,
      [{ type: 'load_track', track: adventureTrack._id }]
    )
    scenariosInButton.push(chaseSuccess._id)

    const chaseFailure = await this.createScenario(
      'Course poursuite - echec',
      [{ type: 'score_conversation', title: 'Course poursuite - echec', scoreType: 'track' }],
      experienceId,
      [{ type: 'load_track', track: adventureTrack._id }]
    )
    scenariosInButton.push(chaseFailure._id)

    const chaseTrack = await api.tracks.create({
      experienceId,
      type: 'chase',
      name: 'Course Poursuite',
      minZoom: 14,
      maxZoom: 18,
      entities: [{ _id: uid.v1(), speed: 1 }],
      failure: chaseFailure._id
    })
    point = await this.createPoint(
      'Chase',
      [{ type: 'conversation', title: 'avant chase' }],
      'https://explorgames.com/qrcode/11',
      adventureTrack,
      [{ type: 'load_track', track: chaseTrack._id }],
      12
    )
    scenariosInButton.push(point.check.scenario)

    await api.points.create({
      name: 'objectif course poursuite ',
      trackId: chaseTrack._id,
      location: [46 + Math.random(), 4 + Math.random()],
      experienceId: xp._id,
      scenario: chaseSuccess._id,
      distance: 15,
      active: true,
      visible: true
    })

    const endPhotoHunt = await this.createScenario(
      'Rally Photo fin',
      [{ type: 'score_conversation', title: 'chase - réussite', scoreType: 'track' }],
      experienceId,
      [{ type: 'load_track', track: adventureTrack._id }]
    )
    scenariosInButton.push(endPhotoHunt._id)

    const photoHuntTrack = await api.tracks.create({
      experienceId,
      type: 'photo_hunt',
      name: 'Rally Photo',
      minZoom: 14,
      maxZoom: 18,
      shufflePoints: false,
      allowNavigation: false,
      scenario: endPhotoHunt._id
    })
    await this.createPoint(
      'Rally Photo',
      [{ type: 'conversation', title: 'avant Rally Photo' }],
      'https://explorgames.com/qrcode/12',
      adventureTrack,
      [{ type: 'load_track', track: photoHuntTrack._id }],
      13
    )

    for (let order = 1; order < 4; order++) {
      await api.points.create({
        name: 'Rallye Photo - point ' + order,
        trackId: photoHuntTrack._id,
        location: [46 + Math.random(), 4 + Math.random()],
        experienceId: xp._id,
        active: true,
        visible: true,
        clue: "Allez à l'endroit où cette photo a été prise et validez votre position !",
        validation: { type: 'location', distance: 15 }
      })
    }
    const menu = []
    const tuto = await this.createScenario(
      'Tutoriel',
      [
        {
          type: 'conversation',
          title: 'tutoriel',
          dialogs: [
            {
              text: "Prêts à commencer une nouvelle aventure ?\nChers explorateurs, apprenons les bases ensemble, c'est parti !",
              position: 'bottom|start'
            },
            {
              text: 'Votre objectif principal est de retrouver les QR codes, cachés autour de vous.',
              position: 'bottom|start'
            },
            {
              text: 'Ils sont localisés sur la carte par des points, comme celui- ci.',
              position: 'bottom|start'
            },
            {
              text: 'Ce marqueur vous donne votre position sur la carte en temps réel.',
              position: 'bottom|start'
            },
            {
              text:
                'Pour vous aider, utilisez les indices photos et l’aiguille de la boussole qui vous indique la direction à suivre.',
              position: 'bottom|start'
            },
            {
              text: 'Lorsque vous avez trouvé le QR code, cliquez sur trouver pour le scanner. Et c’est parti !',
              position: 'bottom|start'
            },
            {
              text: 'Si vous avez besoin de retrouver ce tuto lors de votre aventure, cliquez sur cet icône.',
              position: 'bottom|start'
            }
          ]
        }
      ],
      experienceId,
      []
    )
    scenariosInButton.push(tuto._id)
    for (const scenarioId of scenariosInButton) {
      const scenario = await api.scenarios.get(scenarioId)
      menu.push({ label: scenario.name, action: { type: 'load_scenario', scenario: scenarioId } })
    }
    await api.tracks.update(adventureTrack._id, { menu })
    return adventureTrack
  }

  createExperienceFromTemplate = async () => {
    this.setState({ creating: true })
    const { siteUid: siteId } = this.props.match.params
    const xp = await api.experiences.create({
      siteId,
      locale: 'fr',
      name: new Date(),
      description: 'créer par modèle',
      authorizations: { tracks: toJS(Object.keys(trackLabel)), modules: toJS(Object.keys(moduleLabel)) },
      waitForDownload: true,
      useProgression: true
    })
    // scenario d'intro
    const intro = this.createScenario(
      'Intro',
      [
        { type: 'video', title: 'Teaser' },
        { type: 'conversation', title: 'Introduction' }
      ],
      xp._id
    )
    xp.onStart = [{ type: 'load_scenario', scenario: intro._id }]

    // parcours
    const adventureTrack = await this.createAdventureTrack(xp)
    xp.startTrack = adventureTrack._id

    api.experiences.upsert(xp)

    this.setState({ creating: false })
    this.props.history.push('/site/' + siteId + '/experience/' + xp._id)
    // photo_hunt
  }

  save = formData => {
    const { siteUid } = this.props.match.params
    return api.sites.upsert(formData).then(site => {
      if (!siteUid) {
        const url = '/site/' + site.uid
        this.props.history.push(url)
      }
    })
  }

  rollBack = patchId => {
    const { siteUid } = this.props.match.params
    return api.sites.rollBackToPatch(siteUid, patchId).then(site => this.setState({ site }))
  }

  async componentDidMount() {
    const siteUid = this.props.match.params.siteUid
    if (!siteUid) {
      return
    }
    this.setState({ loading: true })
    const experiences = await api.experiences.getFrom('sites', siteUid)
    const site = await api.sites.get(siteUid)
    const isAdmin = await api.users.amIAnAdmin()
    this.setState({ loading: false, isAdmin, site, experiences })

    // Requête asynchrone qui actualisera le state "prodStatus" en fin de traitement, sans bloquer l'affichage.
    api.releases
      .listFromSite('prod', siteUid) // Liste des expériences en prod.
      .then(experiences => (experiences || []).map(exp => exp._id)) // Extraction de l'ID
      .then(expIds => {
        // Mise à jour du state "prodStatus", en indiquant pour chaque expérience si elle est dans la liste d'IDs.
        const prodStatus = experiences?.reduce((acc, exp) => ({ ...acc, [exp._id]: expIds.includes(exp._id) }), {}) || {}
        this.setState({ prodStatus })
      })
      .catch(() => {}) // En cas d'erreur il ne se passe rien, le state n'est juste pas actualisé.
  }

  /* mobx will force a new render */
  componentWillReact() {
    const { siteUid } = this.props.match.params
    if (siteUid && this.state.site) {
      const site = toJS(apiCache.sites.get(siteUid))
      if (site && this.state.site.updatedAt !== site.updatedAt) {
        this.setState({ site })
      }
    }
  }

  delete = () => {
    const siteUid = this.props.match.params.siteUid
    return api.sites.remove(siteUid).then(() => this.props.history.push('/'))
  }

  render() {
    const { loading, site, creating } = this.state
    const { siteUid } = this.props.match.params
    siteUid && apiCache.sites.get(siteUid) // to inform mobx I need to watch this in this component
    const experiences = (apiCache.experiences.getFrom('sites', siteUid) || []).sort((xp1, xp2) => {
      return xp1.name + '-' + xp1.locale - (xp2.name + '-' + xp2.locale)
    })
    return (
      <Page>
        <NavInSite />
        <div id="content">
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath="site"
              initialFormData={site}
              onDelete={this.delete}
              onSubmit={this.save}
              listHistory={() => api.sites.listAvailableRollBack(siteUid)}
              rollBackToPatch={this.rollBack}
            />
          )}
          <div className="experience-preview-container">
            {experiences.map(xp => {
              const isLoaded = typeof this.state.prodStatus?.[xp._id] === 'boolean'
              const isReleased = !!this.state.prodStatus?.[xp._id]
              return (
                <Link to={'/site/' + siteUid + '/experience/' + xp._id} className="experience-preview" key={xp._id}>
                  <h3>
                    {isLoaded && <span style={{ fontSize: 12 }}>{isReleased ? '🟢 ' : '🟡 '}</span>}
                    {`${xp.name} - ${xp.locale}`}
                  </h3>
                  <div style={{ minHeight: 100 }}>
                    <Picture resourceId={xp.picture} />
                  </div>
                </Link>
              )
            })}
          </div>
          <h4>QrCode à scanner pour ouvrir ce site directement dans l'application explorgames</h4>
          <QRCodeReact
            value={'https://workshop.explor.games/site/' + site._id}
            style={{ padding: '10px' }}
            size={148}
            includeMargin
            level="M"
          />

          {creating && siteUid && (
            <button className="btn btn-default btn-block" disabled>
              {' '}
              Création en cours{' '}
            </button>
          )}
          {!creating && siteUid && (
            <button className="btn btn-default btn-block" onClick={this.createExperienceFromTemplate}>
              {' '}
              Créer un explorgame{' '}
            </button>
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}
export default observer(SiteForm)
