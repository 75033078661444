import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Scenario, Module } from '../'
import PointForm from './form'
import Wizard from './wizard'
import HordeEntity from './hordeentity'
import { PointTrigger } from './pointTrigger'

export default class Point extends Component {
  render () {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.path + '/trigger/'} component={PointTrigger} />
        <Route
          path={match.path + '/scenario/:scenarioUid/module/:moduleUid?'}
          component={Module}
        />

        <Route
          path={match.path + '/scenario/:scenarioUid?'}
          component={Scenario}
        />
        <Route path={match.path + '/wizard'} component={Wizard} />
        <Route path={match.path + '/:pointUid'} component={PointForm} />
        <Route path={match.path} component={PointForm} />
      </Switch>
    )
  }
}

export { HordeEntity }
