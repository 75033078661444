import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loading from '../loading'
import * as api from '../../lib/api'
import { apiCache } from '../../stores'

export default class NavInPoint extends Component {
  state = { track: {}, point: {}, clue: undefined, loading: true }

  populate(trackUid, pointUid) {
    const promises = []
    this.setState({ loading: true })
    if (pointUid) {
      promises.push(
        api.points.get(pointUid).then(async point => {
          if (point) {
            const modules = point?.validation?.clue ? await api.modules.getFrom('scenarios', point.validation.clue) : []
            this.setState({ point, clue: modules?.[0] })
          }
        })
      )
    }
    promises.push(api.tracks.get(trackUid).then(track => this.setState({ track: track || {} })))

    Promise.all(promises).then(() => this.setState({ loading: false }))
  }

  componentDidMount() {
    const { trackUid, pointUid } = this.props.match.params
    this.populate(trackUid, pointUid)
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.trackUid !== nextProps.match.params.trackUid ||
      this.props.match.params.pointUid !== nextProps.match.params.pointUid
    ) {
      this.populate(nextProps.match.params.trackUid, nextProps.match.params.pointUid)
    }
  }

  render() {
    const { siteUid, experienceUid, trackUid, pointUid } = this.props.match.params
    const { loading } = this.state

    if (loading) return <Loading />

    const point = apiCache.points.get(pointUid) || {}
    const track = apiCache.tracks.get(trackUid) || {}

    const clue = point?.check?.clue || point?.validation?.clue
    const firstScenario = point?.check?.scenario || point?.validation?.scenario

    return (
      <nav>
        <div className="nav-block">
          <h3>Point</h3>
          <ul>
            <li>
              <NavLink exact to={`/site/${siteUid}/experience/${experienceUid}/track/${trackUid}/point/${pointUid}`}>
                Général
              </NavLink>
            </li>
            {track && track.type !== 'horde' && track.type !== 'chase' && (
              <li>
                <NavLink to={`/site/${siteUid}/experience/${experienceUid}/track/${trackUid}/point/${pointUid}/trigger/`}>
                  Déclenchement du point
                </NavLink>
              </li>
            )}

            {clue && (
              <li>
                <NavLink to={`/site/${siteUid}/experience/${experienceUid}/track/${trackUid}/point/${pointUid}/scenario/${clue}`}>
                  <span role="img" aria-label="Clue">
                    🔍
                  </span>{' '}
                  Scénario d'indice
                </NavLink>
              </li>
            )}

            {firstScenario && (
              <li>
                <NavLink
                  to={`/site/${siteUid}/experience/${experienceUid}/track/${trackUid}/point/${pointUid}/scenario/${firstScenario}`}
                >
                  <span role="img" aria-label="Arrow">
                    ➡️
                  </span>{' '}
                  Scénario au 1er déclenchement
                </NavLink>
              </li>
            )}
            {point.scenario && (
              <li>
                <NavLink
                  to={`/site/${siteUid}/experience/${experienceUid}/track/${trackUid}/point/${pointUid}/scenario/${point.scenario}`}
                >
                  <span role="img" aria-label="Arrow">
                    ➡️
                  </span>{' '}
                  Scénario par défaut
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
    )
  }
}
