import uuid from 'uuid'
import { toJS } from 'mobx'
import * as api from '../../lib/api'

const DEG2RAD = Math.PI / 180
const polarToCartesian = (lon, lat, radius) => {
  var phi = (90 - lat) * DEG2RAD
  var theta = (lon + 180) * DEG2RAD
  return {
    x: -(radius * Math.sin(phi) * Math.sin(theta)),
    y: radius * Math.cos(phi),
    z: radius * Math.sin(phi) * Math.cos(theta)
  }
}

const RAD2DEG = 180 / Math.PI
const cartesianToPolar = coord => {
  var lon = Math.atan2(coord.x, -coord.z) * RAD2DEG
  var length = Math.sqrt(coord.x * coord.x + coord.z * coord.z)
  var lat = Math.atan2(coord.y, length) * RAD2DEG
  return { lon, lat }
}

export const augmented_reality = {
  json2formData: function(json) {
    console.log('raw augmentedreality data ', json)
    let { targets, ...other } = json
    targets = targets || []
    targets = targets.map(target => {
      const { type, resource, ...otherTarget } = target
      switch (target.type) {
        case 'image':
          return { ...otherTarget, type, image: resource }
        case 'video':
          return { ...otherTarget, type, video: resource }
        case 'object':
          return { ...otherTarget, type, object: resource }
        default:
          return { ...otherTarget, type }
      }
    })
    return { ...other, targets }
  },

  formData2json: function(formData) {
    let { targets, ...other } = formData
    targets = targets || []
    targets = targets.map(target => {
      const { image, video, object, type, ...other } = target
      let resource
      switch (type) {
        case 'image':
          resource = image
          break
        case 'video':
          resource = video
          break
        case 'object':
          resource = object
          break
        default: {
        }
      }
      return { ...other, type, resource }
    })

    return Promise.resolve({ ...other, targets })
  }
}

export const choice = {
  json2formData: function(json) {
    let { defaultChoice, choices, askForConfirmation, ...other } = toJS(json)
    choices = choices || []

    choices = choices.map(choice => {
      return { ...choice, isDefault: defaultChoice === choice._id }
    })

    return { choices, dontAskForConfirmation: !askForConfirmation, ...other }
  },
  formData2json: async function(formData) {
    const { choices, dontAskForConfirmation, ...other } = formData

    let defaultChoice = ''
    for (let counter = 0; counter < (choices || []).length; counter++) {
      const { isDefault, _id } = choices[counter]
      if (isDefault) {
        defaultChoice = _id
      }
    }
    return Promise.resolve({
      ...other,
      askForConfirmation: !dontAskForConfirmation,
      defaultChoice,
      choices
    })
  }
}

export const conversation = {
  formData2json: async function(formData) {
    console.log('will save')
    const { dialogs, experienceId, ...other } = formData

    const experience = await api.experiences.get(experienceId)
    // cut long text and add animation
    const dialogsCut = []
    const positions = ['bottom|start', 'bottom|end']
    let positionIndex = 1
    let lastCharacter = ''
    dialogs &&
      dialogs.forEach(({ text, position, character, icon, sound, ...otherD }) => {
        if (lastCharacter !== character) {
          positionIndex++
          lastCharacter = character
        }
        if (!position) {
          position = positions[positionIndex % positions.length]
        } else {
          positionIndex = positions.findIndex(p => p === position)
        }
        if (character && !icon) {
          ;(text || '').split(/\r\r|\n\n|\r\n\r\n/).forEach((t, index) => {
            dialogsCut.push({
              ...otherD,
              character,
              position,
              text: (t || '').trim(),
              sound: index === 0 ? sound : null,
              animate: index === 0
            })
          })
        } else {
          dialogsCut.push({
            text: (text || '').trim(),
            position,
            character,
            icon,
            sound,
            ...otherD
          })
        }
      })
    const backlog = []
    dialogsCut.forEach((dialog, index) => {
      if (dialog.character) {
        const [characterId, variantId] = dialog.character.split('°|°')
        const character = (experience.characters || []).find(c => c._id === characterId)
        const variant = character && (character.variants || []).find(v => v._id === variantId)
        if (
          dialog.icon ||
          !character ||
          (!character.image && !character.background && !variantId) ||
          (variantId && !variant.image && !variant.background)
        ) {
          return
        }
        backlog.push({
          dialog,
          character,
          variant
        })
      }
    })
    while (backlog.length > 0) {
      const { dialog, character, variant } = backlog.pop()
      const { image: characterImageId, background: characterBackgroundId } = character
      let characterImageUrl
      let characterBackgroundUrl
      if (variant) {
        const { image: variantImageId, background: variantBackgroundId } = variant
        const image = variantImageId ? await api.resources.get(variantImageId) : null
        characterImageUrl = image ? api.resources.getUrl(image.original.file) : null
        const background = variantBackgroundId ? await api.resources.get(variantBackgroundId) : null
        characterBackgroundUrl = background ? api.resources.getUrl(background.original.file) : null
      } else {
        // fall back to character default image
        const image = characterImageId ? await api.resources.get(characterImageId) : null
        characterImageUrl = image ? api.resources.getUrl(image.original.file) : null
        const background = characterBackgroundId ? await api.resources.get(characterBackgroundId) : null
        characterBackgroundUrl = background ? api.resources.getUrl(background.original.file) : null
      }

      if (characterImageUrl) {
        const ops = []
        if (dialog.position === 'bottom|end') {
          ops.push({ horizontal: true, vertical: false, type: 'flip' })
        }
        console.log('IMAGE', dialog, ops)
        const resource = await api.resources.upload({
          url: characterImageUrl,
          type: 'image',
          experienceId: experience._id,
          options: {
            ops
          }
        })
        dialog.icon = resource._id
        dialog.name = character.label
      }
      if (characterBackgroundUrl) {
        const resource = await api.resources.upload({
          url: characterBackgroundUrl,
          type: 'image',
          experienceId: experience._id
        })
        dialog.background = resource._id
      }
    }
    return { dialogs: dialogsCut, experienceId, ...other }
  }
}

export const score_conversation = conversation

export const diaporama = {
  json2formData: json => {
    const pictures = (json?.pictures || []).map(picture => ({
      ...picture,
      ...(typeof picture.time === 'number' ? { time: Math.round(picture.time / 1000) } : {}) // S'il y a un temps il est converti en secondes.
    }))
    return { ...json, pictures }
  },

  formData2json: formData => {
    const pictures = (formData?.pictures || []).map(picture => ({
      ...picture,
      ...(typeof picture.time === 'number' ? { time: Math.round(picture.time) * 1000 } : {}) // S'il y a un temps il est converti en ms.
    }))

    return Promise.resolve({ ...formData, pictures })
  }
}

export const differences = {
  json2formData: function(json) {
    let { differences, ...other } = json
    differences = differences || []
    const scoreZones = differences.map(({ _id, points }) => {
      return { zoneId: _id, points: points || 5 }
    })
    differences = differences.map(({ points, ...other }) => {
      return { ...other }
    })
    return { ...other, differences, scoreZones }
  },
  formData2json: function(formData) {
    let { differences, scoreZones, ...other } = formData
    scoreZones = scoreZones || []
    differences = (differences || []).map(difference => {
      const copy = { ...difference }
      const scoreZone = scoreZones.find(sz => sz.zoneId === difference._id)
      copy.points = scoreZone ? scoreZone.points : 5
      return copy
    })
    return Promise.resolve({ ...other, differences })
  }
}

export const differences_photosphere = {
  json2formData: function(json) {
    let { differences, ...other } = json
    differences = differences || []
    const scoreZones = differences.map(({ _id, points }) => {
      return { zoneId: _id, points: points || 5 }
    })
    differences = differences.map(difference => {
      // compute spherical coordinates, widget.pitch and yaw are the yaw of the widget relative to horinzatal/vert
      const { lon, lat } = cartesianToPolar(difference)
      /* magic numbers everywhere, because fuck future me */
      const yaw = (lon + 270) % 360
      const pitch = lat

      return {
        _id: difference._id,
        left: yaw / 360.0,
        top: 0.5 - pitch / 180
      }
    })
    differences = differences.map(({ points, ...other }) => {
      return { ...other }
    })

    return { ...other, differences, scoreZones }
  },
  formData2json: function(formData) {
    let { differences, scoreZones, ...other } = formData
    differences = differences || []
    differences = differences.map((difference, index) => {
      const lon = (difference.left * 360 + 90) % 360
      const lat = 180 * (0.5 - difference.top)

      const data = polarToCartesian(lon, lat, 1)
      const scoreZone = scoreZones.find(sz => sz.zoneId === difference._id)
      data._id = difference._id || uuid.v4()
      data.points = scoreZone ? scoreZone.points : 5
      data.yaw = lat
      data.pitch = -lon
      data.drop = difference
      return data
    })
    return Promise.resolve({ ...other, differences })
  }
}

export const image_mcq = {
  json2formData: function(json) {
    let { answers, images, ...other } = json
    answers = answers || []
    images = images || []
    images = images.map(image => {
      return { ...image, valid: answers.includes(image._id) }
    })
    return { ...other, answers, images }
  },
  formData2json: function(formData) {
    const { images, ...other } = formData
    const answers = []

    images.forEach(image => {
      if (image.valid) {
        answers.push(image._id)
      }
      delete image.valid
    })
    return Promise.resolve({ ...other, images, answers })
  }
}

export const level = {
  json2formData: json => {
    if (typeof json.codes?.[0] === 'string') json.codes = json.codes.map(code => ({ code }))
    return json
  },
  formData2json: function(formData) {
    if (formData.codes?.length > 0 && !formData?.breaksCompatibility) {
      formData.codes = formData.codes.map(value => value?.code)
    }
    return Promise.resolve(formData)
  }
}

export const mcq = {
  json2formData: function(json) {
    let { propositions, answers, ...other } = json
    propositions = propositions || []
    answers = answers || []
    propositions = propositions.map(label => {
      const valid = answers.includes(label)
      return { valid, label }
    })
    return { ...other, propositions }
  },

  formData2json: function(formData) {
    let { propositions, ...other } = formData
    const answers = []
    propositions = (propositions || []).map(proposition => {
      if (proposition.valid) {
        answers.push(proposition.label)
      }
      return proposition.label
    })
    return Promise.resolve({ ...other, answers, propositions })
  }
}

export const photosphere = {
  json2formData: function(json) {
    let { widgets, ...other } = json
    widgets = widgets || []
    const drops = widgets.map(widget => {
      const { width, height, _id, uid } = widget
      // compute spherical coordinates, widget.pitch and yaw are the yaw of the widget relative to horinzatal/vert
      const { lon, lat } = cartesianToPolar(widget)
      /* magic numbers everywhere, because fuck future me */
      const yaw = (lon + 260) % 360
      const pitch = lat - 10
      return {
        _id: _id || uid,
        left: yaw / 360.0,
        bottom: 0.5 - pitch / 180,
        right: yaw / 360.0 + width * 0.2,
        top: 0.5 - (pitch / 180 + height * 0.3)
      }
    })
    widgets = widgets.map(({ _id, uid, ...other }) => {
      return {
        _id: _id || uid,
        zone: _id || uid,
        ...other
      }
    })
    return { ...other, drops, widgets }
  },

  formData2json: function(formData) {
    let { drops, widgets, ...other } = formData
    drops = drops || []
    widgets = widgets || []
    drops.forEach(drop => {
      /* magic numbers everywhere, because fuck future me */
      const lon = drop.left * 360 - 260
      const lat = 180 * (0.5 - drop.top) + 10
      const lon2 = drop.right * 360 - 260
      const lat2 = 180 * (0.5 - drop.bottom) + 10

      const data = polarToCartesian(Math.min(lon, lon2), Math.min(lat, lat2), 1)
      data.yaw = lat
      data.pitch = -lon
      data.width = Math.abs(drop.left - drop.right) / 0.2
      data.height = Math.abs(drop.top - drop.bottom) / 0.3
      data.drop = drop

      const widget = widgets.find(w => w.zone === drop._id)
      if (widget) {
        Object.assign(widget, data)
        widget._id = widget._id || widget.uid
        delete widget.zone
        delete widget.uid
      }
    })
    return Promise.resolve({ ...other, widgets })
  }
}

export const qrcode_hunt = {
  json2formData: json => {
    if (typeof json.codes?.[0] === 'string') json.codes = json.codes.map(code => ({ code }))
    return json
  },
  formData2json: function(formData) {
    if (formData.codes?.length > 0 && !formData?.breaksCompatibility) {
      formData.codes = formData.codes.map(value => value?.code)
    }
    return Promise.resolve(formData)
  }
}

export const selfie = {
  json2formData: function(json) {
    const { allowSharing, ...other } = json
    return { ...other, disallowSharing: !allowSharing }
  },
  formData2json: function(formData) {
    const { disallowSharing, ...other } = formData
    return Promise.resolve({ ...other, allowSharing: !disallowSharing })
  }
}

export const silence = {
  json2formData: json => {
    if (typeof json.codes?.[0] === 'string') json.codes = json.codes.map(code => ({ code }))
    return json
  },
  formData2json: function(formData) {
    if (formData.codes?.length > 0 && !formData?.breaksCompatibility) {
      formData.codes = formData.codes.map(value => value?.code)
    }
    return Promise.resolve(formData)
  }
}

export const switch_module = {
  json2formData: json => {
    // Au chargement, le JSON récupère le premier cas à la racine, et le place en 1er dans la liste des conditions.
    const cases = (json?.cases || []).map(c => {
      // Si 'key' existe, la première condition utilise les paramètres à la racine de l'objet.
      if (c.key || c.value || c.method)
        c.conditions = [{ key: c.key || '', value: c.value || '', method: c.method || 'equal' }, ...(c.conditions || [])]
      ;['key', 'value', 'method'].forEach(prop => delete c[prop]) // Suppression des propriétes inutiles.
      return c
    })
    return { ...json, cases }
  },

  formData2json: formData => {
    // À l'enregistrement, la première condition de chaque cas est remise à la racine pour des raisons de compatibilité.
    formData.cases = (formData?.cases || []).map(c => {
      const firstCase = c.conditions?.shift()
      return { ...c, ...firstCase }
    })
    return Promise.resolve({ ...formData })
  }
}

export const puzzle = {
  formData2json: function(formData) {
    const { pieces, ...other } = formData
    let promise = Promise.resolve()
    pieces &&
      pieces.forEach(piece => {
        const { picture } = piece
        if (picture) {
          promise = promise
            .then(() => {
              return api.resources.get(picture)
            })
            .then(({ options }) => {
              if (options && options.ops) {
                const crop = options.ops.find(item => item.type === 'crop')
                if (crop) {
                  piece.top = crop.percentCrop.y / 100
                  piece.left = crop.percentCrop.x / 100
                  piece.right = (crop.percentCrop.x + crop.percentCrop.width) / 100
                  piece.bottom = (crop.percentCrop.y + crop.percentCrop.height) / 100
                }
              }
            })
        }
      })
    return promise.then(() => {
      return { ...other, pieces }
    })
  }
}
