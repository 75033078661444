import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

const validationOptions = [
  { value: 'equal', label: '= La valeur est égale à la valeur du switch' },
  { value: 'contain', label: '∈ Le texte du switch contient au moins une partie du texte de la valeur' },
  { value: 'exist', label: '∃ La clé existe, peu importe la valeur' },
  { value: 'greater', label: '> La valeur du switch est strictement supérieure' },
  { value: 'less', label: '< La valeur du switch est strictement inférieure' },
  { value: 'isSolution', label: "🕵🏼 Au moins un des éléments est solution de l'enquête" },
  { value: 'isNotSolution', label: "🕵🏼 Aucun des éléments n'est solution de l'enquête" }
]

export const SwitchCondition = ({ formData, formContext, onChange }) => {
  const { categories } = formContext
  const options = useRef(
    (categories || []).map(category => ({
      label: category.label,
      options: (category.variants || []).map(variant => ({ value: variant._id, label: variant.label }))
    }))
  ).current

  const isInvestigation = ['isSolution', 'isNotSolution'].includes(formData.method)

  const [value, setValue] = useState({ string: '', array: [] })

  useEffect(() => {
    const parsedValue = parseArray(formData?.value, [])
    setValue({
      string: isInvestigation ? '' : formData?.value || '',
      array: options.flatMap(category => category.options || []).filter(option => parsedValue.includes(option.value))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.value, isInvestigation])

  return (
    <div className="form-group field field-object">
      <div className="form-group field field-string">
        <label className="control-label" htmlFor="method">
          Méthode de validation
        </label>
        <select
          id="method"
          value={formData.method || 'equal'}
          className="form-control"
          onChange={event => onChange({ ...formData, method: event.target.value })}
        >
          {validationOptions.map(option => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group field field-string">
        <label className="control-label" htmlFor="key">
          {isInvestigation ? "Identifiant de l'enquête" : 'Clé'}
        </label>
        <input
          id="key"
          type="text"
          className="form-control"
          onChange={event => onChange({ ...formData, key: event.target.value })}
          value={formData.key || ''}
        />
      </div>
      <div className="form-group field field-string">
        <label className="control-label" htmlFor="value">
          {isInvestigation ? 'Éléments' : 'Valeur'}
        </label>
        {isInvestigation ? (
          <Select
            value={value.array}
            onChange={options => onChange({ ...formData, value: JSON.stringify(options.map(option => option.value)) })}
            options={options}
            isMulti={true}
            closeMenuOnSelect={false}
            menuPortalTarget={document.body}
            placeholder={'Sélectionnez un élément'}
            noOptionsMessage={() => "Pas d'élément à afficher"}
            menuPlacement="auto"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        ) : (
          <input
            id="value"
            type="text"
            className="form-control"
            onChange={event => onChange({ ...formData, value: event.target.value })}
            value={value.string}
          />
        )}
      </div>

      <div style={{ marginTop: 30, marginBottom: 30, height: 1, width: 320, backgroundColor: '#0005' }} />
    </div>
  )
}

const parseArray = value => {
  try {
    const parsed = JSON.parse(value)
    return Array.isArray(parsed) ? parsed : []
  } catch {
    return []
  }
}
