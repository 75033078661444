import generify from './generic'
import * as stores from '../../stores'

export const releases = generify('experiences-service', 'releases', 'release')

releases.listFromExperience = function(environnement, experienceId) {
  return fetch(stores.conf.API_URL + '/experiences-service/releases/' + environnement + '/experience/' + experienceId, {
    headers: { Authorization: 'Bearer ' + stores.user.token }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw json.msg
      return json
    })
}
releases.listFromSite = async (environnement, siteId) => {
  return fetch(stores.conf.API_URL + '/experiences-service/releases/' + environnement + '/site/' + siteId, {
    headers: { Authorization: 'Bearer ' + stores.user.token }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw json.msg
      return json
    })
}
